import "../styles/Note.css";

export default function NoteItem({ note = undefined, noteMax = 1, onChange = undefined }) {
  function changeNote(e, nb) {
    e.preventDefault();
    onChange(nb);
  }

  return (
    <ul className="step">
      <li key="N" className={!note && note !== 0 ? "step-item active" : "step-item"}>
        <a href="#N" className="tooltip note" data-tooltip="Non traité" onClick={(e) => changeNote(e, undefined)}>
          N
        </a>
      </li>
      {[...Array(noteMax + 1).keys()].map((nb) => (
        <li key={nb} className={nb === note ? "step-item active" : "step-item"}>
          <a href={"#" + nb} className="tooltip note" data-tooltip={nb} onClick={(e) => changeNote(e, nb)}>
            {nb}
          </a>
        </li>
      ))}
    </ul>
  );
}
