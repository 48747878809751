import "../../styles/Devoirs.css";
import { clone } from "../../utils/fonctions";
import { getSujet, getCorrige } from "../../store/devoirs";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chapitres from "./Chapitres";
import Select from "../Select";
import ScrollView from "../ScrollView";

export default function DevoirHeader({ devoir = undefined, onChange = undefined }) {
  const sources = useSelector((state) => state.devoirs.devoirSources);
  const [sujet, setSujet] = useState("");
  const [source, setSource] = useState("");
  const [points, setPoints] = useState(0);
  const [moyenne, setMoyene] = useState(0);
  const [coeff, setCoeff] = useState(0);
  const [verrouille, setVerrouille] = useState(false);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSujet(devoir?.sujet);
    setSource(devoir?.source);
    setPoints(devoir?.noteDS);
    setVerrouille(devoir?.verrouille);
    setQuestions(devoir?.questions);
    setMoyene(devoir?.moyenne);
    setCoeff(devoir?.coeff);
  }, [devoir]);

  function changeSource(source) {
    var devoirCopy = clone(devoir);
    devoirCopy.source = source;
    onChange?.(devoirCopy);
  }

  function changeSujet(sujet) {
    var devoirCopy = clone(devoir);
    devoirCopy.sujet = sujet;
    onChange?.(devoirCopy);
  }

  function changeVerrouille() {
    var devoirCopy = clone(devoir);
    devoirCopy.verrouille = !devoirCopy.verrouille;
    onChange?.(devoirCopy);
  }

  async function sujetDevoir() {
    if (devoir) {
      await dispatch(getSujet(devoir)).unwrap();
    }
  }

  async function corrigeDevoir() {
    if (devoir) {
      await dispatch(getCorrige(devoir)).unwrap();
    }
  }

  return (
    <>
      <div className="columns">
        <div className="column col-2 col-sm-12 col-md-4 text-center mt-1">
          <span className="titreDevoir">Sujet/Titre</span>
        </div>
        <div className="column col-4 col-sm-12 col-md-8">
          <div className="input-group">
            <input className="form-input" type="text" value={sujet} onChange={(e) => changeSujet(e.target.value)} />
          </div>
        </div>
        <div className="column col-2 text-center mt-1 col-sm-12 col-md-4">
          <span className="titreDevoir">Source</span>
        </div>
        <div className="column col-4  col-sm-12 col-md-8">
          <Select
            elements={sources}
            select={source}
            emptyElement={true}
            emptyElementLabel={"Choisir une source"}
            onSelectChange={(c) => changeSource(c)}
            keySelector={(c) => c.key}
            valueSelector={(c) => c.key}
            labelSelector={(c) => c.value}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column col-2 text-center mt-1 col-md-3">
          <span className="titreDevoir">Points</span>
        </div>
        <div className="column col-1 text-center mt-1 col-md-3">
          <span>{points}</span>
        </div>
        <div className="column col-2 text-center mt-1 col-md-3">
          <span className="titreDevoir">Coeff</span>
        </div>
        <div className="column col-1 text-center mt-1 col-md-3">
          <span>{coeff}</span>
        </div>
        <div className="column col-2 text-center mt-1 col-md-3">
          <span className="titreDevoir">Moyenne</span>
        </div>
        <div className="column col-1 text-center mt-1 col-md-3">
          <span>{moyenne}</span>
        </div>
        <div className="column col hide-md" />
        <div className="column col-auto">
          <div className="form-group tooltip tooltip-left" data-tooltip="Verrouiller le devoir pour les corrections">
            <label className="form-switch">
              <input type="checkbox" checked={verrouille} onChange={() => changeVerrouille()} />
              <i className="form-icon"></i>
              <span className="titreDevoir">Verrouillé</span>
            </label>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column col-2 text-center mt-1 col-sm-12 col-md-4">
          <span className="titreDevoir">Chapitres</span>
        </div>
        <div className="column col-9 mt-1 col-sm-12 col-md-8">
          <Chapitres questions={questions} />
        </div>
      </div>
      <div className="columns">
        <div className="column col-6 col-md-12">
          <button className="btn longButton" onClick={() => sujetDevoir()}>
            Sujet
          </button>
        </div>
        <div className="column col-6 col-md-12">
          <button className="btn longButton" onClick={() => corrigeDevoir()}>
            Corrigé
          </button>
        </div>
      </div>
    </>
  );
}
