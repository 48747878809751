import QuestionNote from "./QuestionNote";

export default class Note {
  _id = "";
  login = "";
  nom = "";
  prenom = "";
  matiere = "";
  groupe = "";
  date = "";
  sujet = "";
  noteDS = 0;
  coeff = 1;
  noteEleve = undefined;
  moyenneClasse = 0;
  questions = [];

  constructor({ _id = "", login = "", nom = "", prenom = "", matiere = "", groupe = "", date = "", sujet = "", noteDS = 0, coeff = 1, noteEleve = undefined, moyenneClasse = 0, questions = [] }) {
    this._id = _id;
    this.login = login;
    this.nom = nom;
    this.prenom = prenom;
    this.matiere = matiere;
    this.groupe = groupe;
    this.date = date;
    this.sujet = sujet;
    this.noteDS = noteDS;
    this.coeff = coeff;
    this.noteEleve = noteEleve;
    this.moyenneClasse = moyenneClasse;
    this.questions = questions.map((q) => new QuestionNote(q));
  }

  get noteEleve20() {
    if (!this.noteEleve20Base) {
      return undefined;
    }
    return Number((this.noteEleve20Base * this.coeff).toFixed(2));
  }
  get noteEleve20Base() {
    if (!this.noteEleve && this.noteEleve !== 0) {
      return undefined;
    }
    return Number(this.noteDS && this.noteDS > 0 ? ((this.noteEleve * 20) / this.noteDS).toFixed(2) : 0);
  }

  get estEvaluer() {
    return this._id !== "";
  }

  get rapportClasse() {
    if (!this.noteEleve20 || !this.moyenneClasse || this.moyenneClasse === 0) {
      return 0;
    }
    return this.noteEleve20 >= this.moyenneClasse ? 1 : -1;
  }

  get nomFull() {
    return `${this.date} - ${this.groupe} - ${this.sujet}`;
  }

  static sort(a, b) {
    const diff = a.groupe.localeCompare(b.groupe);
    return diff === 0 ? -a.date.localeCompare(b.date) : diff;
  }
}
