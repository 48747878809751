import "../../styles/Utilisateur.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEleveNotes, selectEleveNotes } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs } from "../../store/devoirs";
import { getDicoSII } from "../../store/dicoSiiNsi";
import { drawGrid, dicoCarte, minimum } from "../../utils/competence";
import { sortQuestionsNumero } from "../../utils/fonctions";
import UtilisateurTemplate from "./UtilisateurTemplate";
import ScrollView from "../ScrollView";

export default function UtilisateurCompetences() {
  const [selectedChapitre, setSelectedChapitre] = useState({});
  const [selectedHex, setSelectedHex] = useState("");
  const [notesCompetences, setNotesCompetences] = useState([]);
  const eleveNotesStatus = useSelector((state) => state.eleves.statusNote);
  const eleveNotes = useSelector(selectEleveNotes);
  const dicoSIIStatus = useSelector((state) => state.dicos.statusDicoSII);
  const devoirs = useSelector(selectAllDevoirs);
  const devoirsStatus = useSelector((state) => state.devoirs.status);
  const user = useSelector((state) => state.user.user);
  const dicoSII = useSelector((state) => state.dicos.dicoSII);
  const dispatch = useDispatch();

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    drawGrid(ctx, canvas.width, canvas.height, selectedHex);

    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    if (dicoSIIStatus === "idle") {
      dispatch(getDicoSII());
    }
    if (eleveNotesStatus === "idle" && user.login) {
      dispatch(getEleveNotes(user.login));
    }
  }, [dispatch, selectedHex, eleveNotesStatus, devoirsStatus, dicoSIIStatus, user]);

  function selectCanva(eventInfo) {
    const coordClicX = eventInfo.nativeEvent.offsetX || eventInfo.nativeEvent.layerX;
    const coordClicY = eventInfo.nativeEvent.offsetY || eventInfo.nativeEvent.layerY;
    selectionCompetence(coordClicX, coordClicY);
  }

  function selectionCompetence(coordClicX, coordClicY) {
    for (var hex in dicoCarte) {
      const dist = Math.sqrt((dicoCarte[hex].x - coordClicX) ** 2 + (dicoCarte[hex].y - coordClicY) ** 2);
      dicoCarte[hex].dist = dist;
    }
    const hexSelectionne = minimum();
    setSelectedHex(hexSelectionne);
    const chapitre = dicoCarte[hexSelectionne].chapitre;
    const selectedChapitre = dicoSII.dico.filter((e) => e.id === chapitre).shift();
    setSelectedChapitre(selectedChapitre);
    calculNotesPourChapitres(selectedChapitre, devoirs, eleveNotes);
  }

  function calculNotesPourChapitres(selectedChapitre, devoirs, eleveNotes) {
    const notesCompetences = selectedChapitre.contenus.map((c) => {
      const notes = devoirs
        .flatMap((d) => d.questions.map((q) => ({ devoir: d, question: q })))
        .filter((q) => q.question.chapitre === selectedChapitre.id && q.question.competence === c.nom)
        .map((question) => ({
          devoir: question.devoir,
          question: question.question,
          note: eleveNotes
            .filter((n) => n.date === question.devoir.date)
            .flatMap((d) => d.questions)
            .filter((q) => q.numero === question.question.numero)
            .shift(),
        }))
        .filter((questionNote) => questionNote.note);
      const devoirsNotes = notes.reduce((a, d) => {
        if (!a.hasOwnProperty(d.devoir.date)) {
          a[d.devoir.date] = { devoir: d.devoir, questionNotes: [], acquisTotal: 0, acquisEleve: 0 };
        }
        a[d.devoir.date].questionNotes.push({ question: d.question, note: d.note });
        a[d.devoir.date].questionNotes.sort((q1, q2) => sortQuestionsNumero(q1.question.numero, q2.question.numero));
        a[d.devoir.date].acquisTotal += 1;
        a[d.devoir.date].acquisEleve += d.note.acquis === true ? 1 : 0;
        const ratio = a[d.devoir.date].acquisEleve / a[d.devoir.date].acquisTotal;
        a[d.devoir.date].ratio = ratio < 0.2 ? "KO" : ratio > 0.8 ? "OK" : "Pending";
        return a;
      }, {});
      return {
        competence: c,
        chapitre: selectedChapitre,
        devoirsNotes: devoirsNotes,
      };
    });
    setNotesCompetences(notesCompetences);
  }

  return (
    <UtilisateurTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-12">
            <div className="text-center">
              <ScrollView width="100%" height="calc(100vh - 380px)" maxHeight="100%" overflowY="auto" overflowX="auto" >
                <canvas id="canvas" width="700" height="500" onClick={(e) => selectCanva(e)} />
              </ScrollView>
            </div>
          </div>
          {selectedChapitre && (
            <>
              <div className="column col-12 mt-2">
                <h5 className="label label-rounded label-secondary">{selectedChapitre.label}</h5>
              </div>
              {notesCompetences.map((c, i) => (
                <div key={`t${i}`} className="d-inline">
                  <div className="column col-12 mt-2">
                    <span className="label text-bold">{c.competence.titre}</span>
                    <span className="label">{c.competence.label}</span>
                  </div>
                  <div className="column col-12 mt-2">
                    <ul className="step">
                      {Object.keys(c.devoirsNotes)
                        .sort()
                        .reverse()
                        .map((d) => (
                          <li className="timeStep" key={d}>
                            <div className="popover popover-top">
                              {c.devoirsNotes[d].ratio === "OK" && (
                                <span className="btn btn-success">
                                  <i className="icon icon-check"></i>
                                </span>
                              )}
                              {c.devoirsNotes[d].ratio === "KO" && (
                                <span className="btn btn-error">
                                  <i className="icon icon-cross"></i>
                                </span>
                              )}
                              {c.devoirsNotes[d].ratio === "Pending" && (
                                <span className="btn btn-primary">
                                  <i className="icon icon-time"></i>
                                </span>
                              )}
                              <div className="popover-container">
                                <div className="card">
                                  <div className="card-header">{c.devoirsNotes[d].devoir.nom}</div>
                                  <div className="card-body">
                                    <div className="container">
                                      {c.devoirsNotes[d].questionNotes.map((q, j) => (
                                        <div key={`q${i}-${j}`} className="columns">
                                          <div className="column col-2">
                                            <span className="label label-secondary">{q.note.numero}:</span>
                                          </div>
                                          <div className="column col-5">
                                            {!q.note.acquis && <span className="label label-warning ">Non acquis</span>}
                                            {q.note.acquis && <span className="label label-success">Acquis</span>}
                                          </div>
                                          <div className="column col-5">
                                            <span>
                                              {q.note.pointEleve}/{q.note.pointQuestion} points
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="card-footer">{c.devoirsNotes[d].devoir.date}</div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </UtilisateurTemplate>
  );
}
