import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminTemplate from "./AdminTemplate";
import { selectAllEleves, getEleves } from "../../store/eleves";
import { selectAllGroupes, getGroupes } from "../../store/groupes";
import ScrollView from "../ScrollView";
import List from "../List";
import ListSelect from "../ListSelect";

export default function Administration() {
  const [selectedGroupe, setSelectedGroupe] = useState();
  const [selectedGroupeEleves, setSelectedGroupeEleves] = useState([]);
  const [sortArchive, setSortArchive] = useState(false);
  const eleves = useSelector(selectAllEleves);
  const groupes = useSelector(selectAllGroupes);
  const groupesStatus = useSelector((state) => state.eleves.status);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupesStatus === "idle") {
      dispatch(getGroupes());
    }
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
  }, [dispatch, elevesStatus, groupesStatus, groupes]);

  async function changeSelectedGroupe(groupe) {
    majGroupeEleves(groupe, eleves, sortArchive);
    setSelectedGroupe(groupe);
  }

  function changeSortArchive() {
    majGroupeEleves(selectedGroupe, eleves, !sortArchive);
    setSortArchive(!sortArchive);
  }

  function majGroupeEleves(groupe, eleves, sort) {
    const groupeEleves = eleves
      .filter((e) => e.groupes.findIndex((g) => g.nom === groupe.nom && g.annee === groupe.annee) > -1)
      .filter((e) => sort || (!sort && !e.archive))
      .sort((e1, e2) => (e1.nom + e1.prenom).localeCompare(e2.nom + e2.prenom))
    setSelectedGroupeEleves(groupeEleves);
  }

  function calculElementListClassname(e) {
    var className = ["columns"];
    if (e.archive) {
      className.push("text-error");
    }
    return className.join(" ");
  }

   return (
    <AdminTemplate>
      <div className="columns">
        <div className="column col-8 col-md-12">
          <ListSelect maxHeight="calc(100vh - 180px)"
            elements={groupes}
            canAdd={false}
            smallOnly={true}
            selectedElement={selectedGroupe}
            selectedElementChange={(groupe) => changeSelectedGroupe(groupe)}
            filterDeactivated={(groupe) => !groupe.archive}
            filterElement={(groupe, filter) => groupe.nom.toUpperCase().includes(filter.toUpperCase()) || groupe.annee.toString().includes(filter.toUpperCase())}
            sortElement={(groupe1, groupe2) => (groupe1.nom + groupe1.prenom).localeCompare(groupe2.nom + groupe2.prenom)}
          >
            {(groupe) => groupe ? (
              <div>
                <span>{groupe?.nom}</span>
                <span className="float-right">{groupe?.annee}</span>
              </div>
            ) : (
              <span>Choisir un groupe</span>
            )}
          </ListSelect>
        </div>
        {selectedGroupe && (<div className="column col-8 col-md-12">
          <div className="columns my-1">
            <div className="column col">nombre d'élèves: {selectedGroupeEleves.length}</div>
            <div className="column col-auto">
              <label className={"form-switch is-error"}>
                <input type="checkbox" id="input-archive" checked={sortArchive} onChange={() => changeSortArchive()} />
                <i className="form-icon"></i>
              </label>
            </div>
          </div>
          <ScrollView maxHeight="calc(100vh - 320px)" margin="0 0 0 0" overflowX="hidden">
            <List elements={selectedGroupeEleves}>
              {(eleve) => (<div  className={calculElementListClassname(eleve)}>
                <div className="column col">{eleve.nom} {eleve.prenom}</div>
                <div className="column col">{eleve.login}</div>
                <div className="column col"></div>
              </div>)}
            </List>
          </ScrollView>
        </div>)}
      </div>
    </AdminTemplate>
  );
}

