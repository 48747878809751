import Note from "./Note";
import Devoir from "./Devoir";
import QuestionNote from "./QuestionNote";
import QuestionDevoir from "./QuestionDevoir";

export default class DevoirNotes {
  devoir = {};
  notes = [];
  moyenneBase = 0;
  moyenne = 0;

  constructor(devoir = {}, notes = []) {
    this.devoir = devoir;
    notes.sort((n1, n2) => (n1.nom + n1.prenom).localeCompare(n2.nom + n2.prenom));
    this.notes = notes;

    const notesEvalues = this.notes.filter((n) => n.noteEleve20Base && n.noteEleve20Base > 0);
    this.moyenneBase = Number(notesEvalues.length > 0 ? (notesEvalues.reduce((a, n) => a + n.noteEleve20Base, 0.0) / notesEvalues.length).toFixed(2) : 0);
    this.moyenne = Number((this.moyenneBase * this.devoir.coeff).toFixed(2));
  }

  static construire(devoir = {}, notes = [], eleves = []) {

    const groupeValidePourDevoir = (g, d) => {
      return g.classe === d.classe 
          && g.matiere === d.matiere 
          && g.annee === d.annee
    }

    const elevesClasse = eleves.filter((e) => e.groupes.findIndex((groupe) => groupeValidePourDevoir(groupe, devoir)) >= 0);
    const notesDevoir = elevesClasse
      .map((eleve) => {
        var note = notes.filter((n) => n.login === eleve.login).shift();
        if (!note) {
          note = new Note({
            date: devoir.date,
            groupe: devoir.classe,
            login: eleve.login,
            nom: eleve.nom,
            prenom: eleve.prenom,
            sujet: devoir.sujet,
            matiere: devoir.matiere,
            noteDS: devoir.noteDS,
            coeff: devoir.coeff,
            questions: devoir.questions.map((q) => {
              return new QuestionNote({
                numero: q.numero,
                pointQuestion: q.pointQuestion,
                pointEleve: undefined,
                acquis: false,
                origine: q,
              });
            }),
          });
        } else {
          note = new Note(note);
          note.nom = eleve.nom;
          note.prenom = eleve.prenom;
          note.coeff = devoir.coeff;
          note.questions.forEach((question) => {
            question.origine = devoir.questions.filter((q) => q.numero === question.numero).shift();
          });
        }
        if (!note.noteDS || note.noteDS === 0) {
          note.noteDS = devoir.questions.reduce((a, q) => a + q.pointQuestion, 0);
        }
        return note;
      });
    return new DevoirNotes(devoir, notesDevoir);
  }
  
  changeCoeff(coeff) {
    const devoir = new Devoir({...this.devoir, coeff: coeff.toFixed(2)});
    const notes = this.notes.map(n => new Note({...n, coeff: coeff.toFixed(2)}));
    return new DevoirNotes(devoir, notes); 
  }

  moyenneDesire(moyenne) {
    const coeff = moyenne / this.moyenneBase;
    return this.changeCoeff(coeff);
  }
    
}

