export default function Select({
  elements = [],
  disabled = false,
  select = undefined,
  emptyElement = false,
  emptyElementLabel = "",
  onSelectChange = undefined,
  defaultValue = undefined,
  keySelector = (e, i) => i,
  valueSelector = (e, i) => e,
  labelSelector = (e, i) => e,
}) {
  return (
    <select
      className="form-select"
      value={select}
      disabled={disabled}
      onChange={(e) => onSelectChange?.(e.target.value)}
    >
      {emptyElement && (
        <option disabled key="" value="">
          {emptyElementLabel}
        </option>
      )}
      {elements.map((e, i) => (
        <option key={keySelector?.(e, i)} value={valueSelector?.(e, i)}>
          {labelSelector?.(e, i)}
        </option>
      ))}
    </select>
  );
}
