import "../../styles/Devoirs.css";
import { useEffect, useState } from "react";
import { clone } from "../../utils/fonctions";
import Questions from "./Questions";
import Accordion from "../Accordion";
import Chapitres from "./Chapitres";

export default function Exercice({
  exercice = undefined,
  dico = undefined,
  onChange = undefined,
  onDelete = undefined,
}) {
  const [questions, setQuestions] = useState([]);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    setQuestions(exercice.questions);
    setPoints(exercice.points);
  }, [exercice]);

  function changeQuestions(questions) {
    const newExercice = clone(exercice);
    questions.forEach((q, i) => (q.numero = `${exercice.numero}Q${i + 1}`));
    setQuestions(questions);
    newExercice.questions = questions;
    newExercice.points = questions.reduce((a, q) => a + q.pointQuestion, 0);
    newExercice.chapitres = questions
      .map((q) => q.chapitre)
      .filter((c, i, a) => c && c !== "" && a.indexOf(c) === i)
      .sort();
    setPoints(newExercice.points);
    onChange?.(newExercice);
  }

  return (
    <div className="column col-12">
      <Accordion
        keySuffix={"ac-ex-" + exercice.numero}
        title={"Partie " + exercice.numero}
        defaultChecked={true}
        headerTemplate={
          <div className="columns mt-1">
            <div className="column col-2 col-md-12">
              <span>Points: {points}</span>
            </div>
            <div className="column col-9 col-md-12">
              <span>
                Chapitres:{" "}
                <Chapitres questions={questions} container={false} />
              </span>
            </div>
            {points === 0 && (
              <div className="column col-1">
                <button
                  className="btn btn-action s-circle btn-sm tooltip tooltip-top"
                  data-tooltip="Supprimer un exercice"
                  onClick={() => onDelete?.(exercice)}
                >
                  <i className="icon icon-cross"></i>
                </button>
              </div>
            )}
          </div>
        }
      >
        <Questions
          questions={questions}
          dico={dico}
          onChange={(qs) => changeQuestions(qs)}
        />
      </Accordion>
    </div>
  );
}
