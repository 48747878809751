import "../../styles/Devoirs.css";
import { useState } from "react";
import Question from "./Question";
import { clone } from "../../utils/fonctions";

export default function Questions({
  questions = [],
  dico = undefined,
  onChange = undefined,
  addExercice = undefined,
}) {
  const [questionDrag, setQuestionDrag] = useState([]);
  const [questionDrop, setQuestionDrop] = useState([]);

  function onDrop(question) {
    const questionsDD = [];
    questions.forEach((q) => {
      if (q === questionDrop) {
        questionsDD.push(questionDrag);
      }
      if (q !== questionDrag) {
        questionsDD.push(q);
      }
    });
    onChange?.(questionsDD);
  }

  function onDelete(question) {
    onChange?.(questions.filter((q) => q.numero !== question.numero));
  }

  function onQuestionChange(question) {
    const newQuestions = questions.map((q) =>
      q.numero === question.numero ? question : q
    );
    onChange?.(newQuestions);
  }

  function addQuestion() {
    const newQuestions = clone(questions);
    newQuestions.push({ numero: 42, pointQuestion: 1 });
    onChange?.(newQuestions);
  }

  return (
    <div className="container">
      <div className="columns">
        {questions?.length > 0 && (
          <div className="colmun col-12 headerColumn">
            <div className="columns">
              <div className="column col-auto headerQuestion text-center">
                <div className="headerNumero">
                  <span>N°</span>
                </div>
              </div>
              <div className="column col-3 headerQuestion text-center">
                <span>Chapitre</span>
              </div>
              <div className="column col-3 headerQuestion text-center">
                <span>Competence</span>
              </div>
              <div className="column col-3 headerQuestion text-center">
                <span>Notion</span>
              </div>
              <div className="column col-2 headerQuestion text-center">
                <span>Points</span>
              </div>
            </div>
          </div>
        )}
        {questions.map((q) => (
          <Question
            key={q.numero}
            question={q}
            dico={dico}
            onDragStart={(q) => setQuestionDrag(q)}
            onDragEnter={(q) => setQuestionDrop(q)}
            onDragEnd={(q) => onDrop(q)}
            onDelete={(q) => onDelete(q)}
            onChange={(q) => onQuestionChange(q)}
          />
        ))}
        {addExercice && (
          <div className="my-2">
            <button
              className="btn btn-action s-circle tooltip tooltip-top"
              data-tooltip="Ajouter un exercice"
              onClick={() => addExercice()}
            >
              <i className="icon icon-plus"></i>
            </button>
          </div>
        )}
        <div className="p-centered my-2">
          <button
            className="btn btn-action btn-Success s-circle tooltip tooltip-top"
            data-tooltip="Ajouter une question"
            onClick={() => addQuestion()}
          >
            <i className="icon icon-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
