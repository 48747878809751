export default class QuestionNote {
    numero = "";
    pointQuestion = 0;
    pointEleve = undefined;
    acquis = false;
    origine = {};
  
    constructor({
      numero = "",
      pointQuestion = 0,
      pointEleve = undefined,
      acquis = false,
      origine = {},
    }) {
      this.numero = numero;
      this.pointQuestion = pointQuestion;
      this.pointEleve = pointEleve;
      this.acquis = acquis;
      this.origine = origine;
    }
}