import { post } from "../utils/fonctions";
import { useDispatch } from 'react-redux'
import { connect } from '../store/user'
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginValid, setLoginValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [erreurAuth, setErreurAuth] = useState(false);
  const dispatch = useDispatch()

  let navigate = useNavigate();

  function authentifier(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    const loginValid = login && login?.trim().length > 0;
    const passwordValid = login && login?.trim().length > 0;
    setErreurAuth(false);
    setLoginValid(loginValid);
    setPasswordValid(passwordValid);

    if (!loginValid || !passwordValid) {
      return;
    }

    const data = { login: login, mdp: password };
    post('/api/identification', data)
      .then((data) => {
        if (data.erreur) {
          
          setErreurAuth(true);
          return;
        }
        dispatch(connect(data));
        if (data.admin === true) {
          navigate(`/administration`);
        } else {
          navigate(`/utilisateur`);
        }
      }).catch((data) => {
        setErreurAuth(true);
      });

  }

  return (
    <div className="container grid-md">
      <form method="post" onSubmit={authentifier}>
        <div className={loginValid ? 'form-group' : 'form-group has-error' }>
          <label className="form-label" htmlFor="input-login">
            Login
          </label>
          <input
            className="form-input"
            type="text"
            id="input-login"
            placeholder="Login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
           <p className={loginValid ? 'form-input-hint d-none' : 'form-input-hint'}>Le login est requis.</p>
        </div>

        <div className={passwordValid ? 'form-group' : 'form-group has-error' } >
          <label className="form-label" htmlFor="input-pass">
            Mot de passe
          </label>
          <input
            className="form-input"
            type="password"
            id="input-pass"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className={passwordValid ? 'form-input-hint d-none' : 'form-input-hint'}>Le mot de passe est requis.</p>
        </div>
        <span className={erreurAuth ? 'text-error' : 'd-none'}>Connection impossible. Merci de vérifier login et mot de passe.</span>
        <br/>
        <br/>
        <button className="btn btn-primary p-centered mt-2">Connexion</button>
      </form>
    </div>
  );
}
