export default function ScrollView({
  children = undefined,
  width = "100%",
  height = "",
  maxHeight = "100px",
  overflowY = "auto",
  overflowX = "none",
  margin = "0",
}) {
  const styles = {
    width: width,
    height: height,
    maxHeight: maxHeight,
    overflowY: overflowY,
    overflowX: overflowX,
    margin: margin,
    padding: "5px"
  };

  return <div style={styles}>{children}</div>;
}
