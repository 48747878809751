import "../styles/Accueil.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDicoNSI, getDicoSII } from "../store/dicoSiiNsi";
import { clone } from "../utils/fonctions";
import { NavLink } from "react-router-dom";
import Plot from "react-plotly.js";

function grouByTitre(contenus) {
  if (!contenus) {
    return;
  }

  return contenus.reduce((acc, contenu) => {
    if (!acc[contenu.titre]) {
      acc[contenu.titre] = [];
    }
    acc[contenu.titre].push(contenu);
    return acc;
  }, {});
}

export default function Accueil() {
  const [matiere, setMatiere] = useState("");
  const [SIIState, setSIIState] = useState();
  const [NSIState, setNSIState] = useState();
  const [selectedElement, setSelectedElement] = useState();
  const dicoNSIStatus = useSelector((state) => state.dicos.statusDicoNSI);
  const dicoNSI = useSelector((state) => state.dicos.dicoNSI);
  const dicoSIIStatus = useSelector((state) => state.dicos.statusDicoSII);
  const dicoSII = useSelector((state) => state.dicos.dicoSII);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dicoNSIStatus === "idle") {
      dispatch(getDicoNSI());
    }
    if (dicoSIIStatus === "idle") {
      dispatch(getDicoSII());
    }
    if (dicoSII && !SIIState) {
      setSIIState({ data:clone(dicoSII?.data), layout:clone(dicoSII?.layout),frames: [], config: {} });
    }
    if (dicoNSI && !NSIState) {
      setNSIState({ data:clone(dicoNSI?.data), layout:clone(dicoNSI?.layout),frames: [], config: {} });
    }
  }, [dispatch, dicoSIIStatus, dicoNSIStatus, dicoSII, dicoNSI, SIIState, NSIState]);

  function affichageLienCours() {
    const nom = selectedElement.nom.replace('<br>', ' ');
    return selectedElement.cours ? (
      <NavLink to={`/cours/${matiere}/${selectedElement.id}`}>
        <h2>{selectedElement.id} - {nom}</h2>
      </NavLink>
    ) : (
      <h2>{nom}</h2>
    );
  }
  function affichageContenu() {
    const groupTitre = grouByTitre(selectedElement.contenus);
    if (!groupTitre) {
      return;
    }
    return Object.keys(groupTitre).map((titre, i) => {
      const contenus = groupTitre[titre];
      return (
        <div key={`cours${i}`}>
          {titre !== "" && <h5>{titre}</h5>}
          {contenus.map((c, j) => (<div key={`cours${i}-${j}`}><span>{`✔ ${c.nom} - ${c.libelle}`}</span></div>))}
        </div>
      );
    });
  }
  function affichageCours() {
    return (
      <div>
        {affichageLienCours()}
        {affichageContenu()}
      </div>
    );
  }

  function clickCamembert(donnees, data, matiere) {
    const pos_liste = data.points[0].pointNumber;
    var elem = donnees.dico[pos_liste];
    if (elem === selectedElement) {
      if (elem.parent && elem.parent !== "") {
        elem = donnees.dico.filter((x) => x.id === elem.parent)[0];
      } else {
        elem = {};
      }
    }
    if (!elem.id || elem.id === matiere ) {
      matiere = "";
    }
    setSelectedElement(elem);
    setMatiere(matiere);
  }

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-6 col-md-12">
          {matiere === "1NSI"
            ? affichageCours()
            : SIIState && (
                <Plot
                  data={SIIState.data}
                  layout={SIIState.layout}
                  frames={SIIState.frames}
                  config={SIIState.config}
                  onInitialized={(figure) => setSIIState(figure)}
                  onUpdate={(figure) => setSIIState(figure)}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  onClick={(data) => clickCamembert(dicoSII, data, "SII")}
                />
              )}
        </div>
        <div className="column col-6 col-md-12">
          {matiere === "SII"
            ? affichageCours()
            : NSIState && (
                <Plot
                  data={NSIState.data}
                  layout={NSIState.layout}
                  frames={NSIState.frames}
                  config={NSIState.config}
                  onInitialized={(figure) => setNSIState(figure)}
                  onUpdate={(figure) => setNSIState(figure)}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                  onClick={(data) => clickCamembert(dicoNSI, data, "1NSI")}
                />
              )}
        </div>
      </div>
    </div>
  );
}
