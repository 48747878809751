import { NavLink } from "react-router-dom";

const menuItems = [
  {
    to: "/administration/eleves",
    name: "Eleves",
    tooltip: "Ajout eleves",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  {
    to: "/administration/groupes",
    name: "Groupes",
    tooltip: "Evolution groupe",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  {
    to: "/administration/devoirs",
    name: "Devoirs",
    tooltip: "Ajout devoirs",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  {
    to: "/administration/evaluer",
    name: "Evaluer",
    tooltip: "Evaluer un devoir",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  // {
  //   to: "/administration/outils",
  //   name: "Outils",
  //   tooltip: "Outils",
  //   tooltipDirection: "right",
  //   icon: "home-fill",
  // },
];

export default function AdminMenu() {
  function menuItem(param) {
    return (
      <NavLink
        to={param.to}
        className={({ isActive, isPending }) =>
          isActive ? "bg-primary text-light p-2 tooltip tooltip-" + param.tooltipDirection : "p-2 tooltip tooltip-" + param.tooltipDirection
        }
        data-tooltip={param.tooltip}
      >
        {param.name}
      </NavLink>
    );
  }

  return (
    <>
      <ul className="nav hide-sm">
        {menuItems.map((m) => (
          <li key={m.name} className="nav-item">
            {menuItem(m)}
          </li>
        ))}
      </ul>
      <ul className="nav show-sm" style={{overflowX:"hidden", overflowY:"hidden"}}>
        {menuItems.map((m) => (
          <li key={m.name} className="nav-item d-inline" style={{lineHeight: "32px"}}>
            {menuItem(m)}
          </li>
        ))}
      </ul>
    </>
  );
}
