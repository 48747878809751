import { useEffect, useState } from "react";
import ScrollView from "./ScrollView";
import List from "./List";

export default function ListSelect({
  maxHeight = "100%",
  elements = [],
  children = undefined,
  selectedElement = undefined,
  selectedElementChange = undefined,
  addElement = undefined,
  filterElement = undefined,
  smallOnly = false,
  canAdd = true,
  filterDefault = false,
  filterStyle = "error",
  canFilterDeactivated = true,
  isSelected = (e, selectedElement) => e._id === selectedElement?._id,
  filterDeactivated = undefined,
  sortElement = undefined,
}) {
  const [sortDeactivated, setSortDeactivated] = useState(filterDefault);
  const [displayElements, setDisplayElements] = useState();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (elements) {
      var displayElements = elements.slice();
      if (filter && filterElement) {
        displayElements = displayElements.filter((e) => filterElement(e, filter));
      }
      if (!sortDeactivated && filterDeactivated) {
        displayElements = displayElements.filter((e) => filterDeactivated(e));
      }
      if (sortElement) {
        displayElements.sort((e1, e2) => sortElement(e1, e2));
      }
      setDisplayElements(displayElements);
    }
  }, [elements, filter, sortDeactivated, filterElement, filterDeactivated, sortElement]);

  async function changeSelectedElement(e, element) {
    e.preventDefault();
    selectedElementChange(element);
    setOpen(false);
  }

  function calculElementListClassname(e) {
    var className = [];
    if (e.archive) {
      className.push("text-error");
    }
    if (isSelected(e, selectedElement)) className.push("active");
    return className.join(" ");
  }

  function choosePart() {
    return (
      <>
        <div className="columns mt-2">
          <div className="column col">
            <div className="input-group">
              <input className="form-input" type="text" id="input-sort" placeholder="rechercher" value={filter} onChange={(e) => setFilter(e.target.value)} />
              <button className="btn btn-secondary input-group-btn" disabled={filter === ""} onClick={(e) => setFilter("")}>
                <i className="icon icon-cross"></i>
              </button>
            </div>
          </div>
          {canFilterDeactivated && (
            <div className="column col-auto">
              <label className={"form-switch is-" + filterStyle}>
                <input type="checkbox" id="input-archive" checked={sortDeactivated} onChange={() => setSortDeactivated(!sortDeactivated)} />
                <i className="form-icon"></i>
              </label>
            </div>
          )}
        </div>
        <div className="columns">
          <ScrollView maxHeight={maxHeight} margin="0 0 0 0">
            <List elements={displayElements} keyPrefix={"element"}>
              {(element) => (
                <a href={"#" + element.login} onClick={(e) => changeSelectedElement(e, element)} className={calculElementListClassname(element)}>
                  {children?.(element) ?? element.toString()}
                </a>
              )}
            </List>
          </ScrollView>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={smallOnly ? "container" : "container show-md"}>
        <div className="columns">
          <div className="col col-12">
            <div class="input-group mt-1">
              <div class="input-group-addon" onClick={() => setOpen(!open)}>
                {!open && <i className="icon icon-arrow-right mt-1"></i>}
                {open && <i className="icon icon-arrow-down mt-1"></i>}
              </div>
              <div class="form-input text-clip" onClick={() => setOpen(!open)}>
                {children?.(selectedElement) ?? selectedElement.toString()}
              </div>
              {canAdd && (
                <button class="btn btn-success input-group-btn" style={{ width: "150px" }} onClick={() => addElement?.()}>
                  <i className="icon icon-plus"></i> Nouveau
                </button>
              )}
            </div>
          </div>
        </div>
        {open && (
          <div className="columns">
            <div className="col col-12">{choosePart()}</div>
          </div>
        )}
      </div>
      <div className={smallOnly ? "container d-none" : "container hide-md"}>
        <div className="columns">
          {canAdd && (
            <div className="column col">
              <button className="btn btn-success longButton mt-2" onClick={() => addElement?.()}>
                <i className="icon icon-plus"></i> Nouveau
              </button>
            </div>
          )}
        </div>
        {choosePart()}
      </div>
    </>
  );
}
