import "../../styles/Utilisateur.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Note from "../../models/Note";
import { getEleveNotes, selectEleveNotes } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs, getSujet, getCorrige, getCopie } from "../../store/devoirs";
import UtilisateurTemplate from "./UtilisateurTemplate";
import ListSelect from "../ListSelect";
import Question from "../Devoirs/Question";

export default function UtilisateurDevoirs() {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState();
  const eleveNotesStatus = useSelector((state) => state.eleves.statusNote);
  const eleveNotes = useSelector(selectEleveNotes);
  const devoirs = useSelector(selectAllDevoirs);
  const devoirsStatus = useSelector((state) => state.devoirs.status);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    if (eleveNotesStatus === "idle" && user.login) {
      dispatch(getEleveNotes(user.login));
    }
    if (eleveNotes && devoirs) {
      var notes = eleveNotes.map((n) => {
        const note = new Note(n);
        var devoir = devoirs.filter((d) => d.date === note.date).shift();
        note.questions?.forEach((question) => {
          question.origine = devoir?.questions?.filter((q) => q.numero === question.numero).shift();
          if (!question.origine) {
            return;
          }

          let color = "";
          if (question.origine.chapitre.indexOf("SLCI") !== -1) {
            color = "#96CBD0";
          } else if (question.origine.chapitre.indexOf("SED") !== -1) {
            color = "#96CBD0";
          } else if (question.origine.chapitre.indexOf("IS") !== -1) {
            color = "#B1C9BB";
          } else if (question.origine.chapitre.indexOf("MEC") !== -1) {
            color = "#65C5E7";
          } else if (question.origine.chapitre.indexOf("DYN") !== -1) {
            color = "#65C5E7";
          } else if (question.origine.chapitre.indexOf("STAT") !== -1) {
            color = "#65C5E7";
          } else if (question.origine.chapitre.indexOf("CIN") !== -1) {
            color = "#65C5E7";
          } else if (question.origine.chapitre.indexOf("IN") !== -1) {
            color = "#E0DBAD";
          }
          question.color = color;
        });
        return note;
      });
      notes.sort((note1, note2) => note2.date.localeCompare(note1.date));
      setNotes(notes);
    }
  }, [dispatch, eleveNotesStatus, devoirsStatus, eleveNotes, devoirs, user]);

  function changeSelectedNote(note) {
    setSelectedNote(note);
  }

  async function sujetDevoir() {
    if (selectedNote) {
      await dispatch(getSujet(selectedNote)).unwrap();
    }
  }

  async function corrigeDevoir() {
    if (selectedNote) {
      await dispatch(getCorrige(selectedNote)).unwrap();
    }
  }

  async function copieDevoir() {
    if (selectedNote) {
      await dispatch(getCopie(selectedNote)).unwrap();
    }
  }

  return (
    <UtilisateurTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-5 col-md-12">
            <ListSelect
              maxHeight="calc(100vh - 100px)"
              elements={notes}
              canAdd={false}
              selectedElement={selectedNote}
              selectedElementChange={(note) => changeSelectedNote(note)}
              canFilterDeactivated={false}
              filterElement={(note, filter) => note.nomFull.toUpperCase().includes(filter.toUpperCase())}
              sortElement={Note.sort}
            >
              {(note) => note ? (
                <span>{note?.nomFull}</span>
              ) : (<span>Choisir un devoir</span>)}
            </ListSelect>
          </div>
          <div className="column col-7 col-md-12 mt-2">
            {selectedNote && (
              <div className="container">
                <div className="columns">
                  <div className="column col-12 hide-md">
                    <h5 className="label label-rounded label-secondary">{selectedNote.nomFull}</h5>
                  </div>
                  <div className="column col-12 mb-2">
                    <div className="columns">
                      <div className="column col-3">
                        <button className="btn longButton" onClick={() => sujetDevoir()}>
                          Sujet
                        </button>
                      </div>
                      <div className="column col-3">
                        <button className="btn longButton" onClick={() => corrigeDevoir()}>
                          Corrigé
                        </button>
                      </div>
                      <div className="column col-3">
                        <button className="btn longButton" onClick={() => copieDevoir()}>
                          Copie
                        </button>
                      </div>
                      <div className="column col-3 mt-2">
                        <h6>
                          Note: {selectedNote.noteEleve}/{selectedNote.noteDS}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {selectedNote.questions.slice().sort(Question.sort).map((question) => (
                    <div key={question.numero} className="column col-12">
                      <div className="columns noteQuestion">
                        <div className="column col-1">
                          <label className="text-bold numeroQ">{question.numero}</label>
                        </div>
                        <div className="column col-6" style={{ border: "2px solid " + question.color }}>
                          {question.origine.chapitre} - {question.origine.notion}
                        </div>
                        {(question.pointEleve || question.pointEleve === 0) && (
                          <div className="column col-2">
                            {!question.acquis && <span className="label label-warning ">Non acquis</span>}
                            {question.acquis && <span className="label label-success">Acquis</span>}
                          </div>
                        )}
                        <div className="column col-3">
                          {(question.pointEleve || question.pointEleve === 0) && (
                            <span>
                              {question.pointEleve}/{question.pointQuestion} pts
                            </span>
                          )}
                          {!question.pointEleve && question.pointEleve !== 0 && <span>Non traité</span>}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </UtilisateurTemplate>
  );
}
