import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../utils/fonctions";

const initialState = {
  devoirNotes: undefined,
  statusDevoirNotes: "idle",
  errorDevoirNote: null,
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    resetDevoirNotes: (state) => {
      state.status = "idle";
      state.devoirNotes = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDevoirNotes.pending, (state, action) => {
        state.devoirNotes = undefined;
        state.status = "loading";
      })
      .addCase(getDevoirNotes.fulfilled, (state, action) => {
        state.devoirNotes = action.payload;
        state.status = "succeeded";
      })
      .addCase(getDevoirNotes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })
  },
});

export const { resetDevoirNotes } = notesSlice.actions;

export const selectDevoirNotes = (state) => state.notes.devoirNotes;

export const getDevoirNotes = createAsyncThunk("get/devoirNotes", async (date) => {
  return await get(`/api/devoirs/${date}/notes`);
});

export default notesSlice.reducer;
