import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, download } from "../utils/fonctions";
import Devoir from "../models/Devoir"

const devoirSources = [
  { key: "CCINP", value: "Concours CCINP" },
  { key: "E3A", value: "Concours E3A" },
  { key: "Centrale", value: "Concours Centrale" },
  { key: "Mines", value: "Concours Mines Ponts" },
  { key: "STI2D", value: "Bac STI2D" },
  { key: "autre", value: "Autre" },
];

const initialState = {
  devoirs: [],
  devoirSources: devoirSources,
  status: "idle",
  error: null,
};

const devoirsSlice = createSlice({
  name: "devoirs",
  initialState,
  reducers: {
    resetDevoir: (state) => {
      state.status = "idle";
      state.devoirs = [];
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDevoirs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDevoirs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.devoirs = action.payload;
      })
      .addCase(getDevoirs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addDevoir.fulfilled, (state, action) => {
        var devoirs = state.devoirs.filter(d => d.date !== action.payload.date);
        devoirs.push(action.payload);
        state.devoirs = devoirs;
      });
  },
});

export const { resetDevoir } = devoirsSlice.actions;

export const selectAllDevoirs = (state) => state.devoirs.devoirs;

export const getDevoirs = createAsyncThunk("get/devoirs", async () => {
  return await get("/api/devoirs");
});

export const addDevoir = createAsyncThunk("posts/devoirs", async (devoir) => {
  return await post("/api/devoirs", devoir);
});

export const getSujet = createAsyncThunk("get/document", async (devoir) => {
  const nom = devoir.nomFull ? devoir.nomFull : devoir.nom;
  return await get(`/api/devoirs/${devoir.date}/sujet`, { responseType: "blob" } ).then((blob) => {
    if (blob.size > 0) {
      download(`${nom}_sujet.pdf`, blob);
    }
  });
});

export const getCorrige = createAsyncThunk("get/document", async (devoir) => {
  const nom = devoir.nomFull ? devoir.nomFull : devoir.nom;
  return await get(`/api/devoirs/${devoir.date}/corrige`, { responseType: "blob" } ).then((blob) => {
    if (blob.size > 0) {
      download(`${nom}_corrige.pdf`, blob);
    }
  });
});

export const getCopie = createAsyncThunk("get/document", async (devoir) => {
  const nom = devoir.nomFull ? devoir.nomFull : devoir.nom;
  return await get(`/api/devoirs/${devoir.date}/copie`, { responseType: "blob" } ).then((blob) => {
    if (blob.size > 0) {
      download(`${nom}_copie.pdf`, blob);
    }
  });
});


export default devoirsSlice.reducer;
