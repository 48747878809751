export default function ListElement({
  elements = [],
  children = undefined,
  canDelete = false,
  onDelete = undefined,
}) {
  return (
    <ul className="menu">
      {
        elements.map((e, i) => (
          <li key={i} className="menu-item" >
            <div className="container">
              <div className="columns">
                <div className="column col">
                  {children(e)}
                </div>
                {canDelete && (<div className="column col-auto">
                  <button className="btn btn-sm btn-error input-group-btn s-circle" onClick={() => onDelete?.(e)}>
                    <i className="icon icon-cross"></i>
                  </button>
                </div>)}
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  );
}
