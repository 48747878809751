import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminTemplate from "./AdminTemplate";
import { selectAllEleves, getEleves, addEleve, addEleves } from "../../store/eleves";
import { selectAllGroupes, getGroupes } from "../../store/groupes";
import { generatePass, cleanNom, generateLogin, clone } from "../../utils/fonctions";
import ScrollView from "../ScrollView";
import List from "../List";
import Modal from "../Modal";
import Select from "../Select";
import ListSelect from "../ListSelect";

export default function AdminEleves() {
  const [modalNouveauActive, setModalNouveauActive] = useState(false);
  const [nouveauxEleves, setNouveauxEleves] = useState([]);
  const [selectedEleve, setSelectedEleve] = useState();
  const [selectedGroupe, setSelectedGroupe] = useState("");
  const [groupesPool, setGroupePool] = useState();
  const eleves = useSelector(selectAllEleves);
  const groupes = useSelector(selectAllGroupes);
  const groupesStatus = useSelector((state) => state.eleves.status);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupesStatus === "idle") {
      dispatch(getGroupes());
    }
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (groupes) {
      setGroupePool(groupes.filter((g) => !g.archive));
    }
  }, [dispatch, elevesStatus, groupesStatus, groupes]);

  async function changeSelectedEleve(eleve) {
    setSelectedGroupe("");
    setSelectedEleve(eleve);
  }

  function changeNom(value) {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.nom = value;
    setSelectedEleve(cloneEleve);
  }

  function changePrenom(value) {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.prenom = value;
    setSelectedEleve(cloneEleve);
  }

  function changeLogin(value) {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.login = value;
    setSelectedEleve(cloneEleve);
  }

  function changePassword(value) {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.mdp = value;
    setSelectedEleve(cloneEleve);
  }

  function changeArchive() {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.archive = !cloneEleve.archive;
    setSelectedEleve(cloneEleve);
  }

  function randPassword() {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.mdp = generatePass();
    setSelectedEleve(cloneEleve);
  }

  function ajouteGroupe() {
    const index = selectedGroupe.indexOf(" ");
    const nom = selectedGroupe.substring(0, index);
    const annee = parseInt(selectedGroupe.substring(index + 1));
    if (selectedGroupe && selectedGroupe !== "") {
      const cloneEleve = clone(selectedEleve);
      const groupe = groupesPool.filter((g) => g.nom === nom && g.annee === annee).shift();
      cloneEleve.groupes.push(groupe);
      setSelectedEleve(cloneEleve);
    }
  }

  function supprimeGroupe(groupe) {
    const cloneEleve = clone(selectedEleve);
    cloneEleve.groupes = cloneEleve.groupes.filter((g) => g.nom !== groupe.nom && g.annee !== groupe.annee);
    setSelectedEleve(cloneEleve);
  }

  async function sauvegarder() {
    await dispatch(addEleve(selectedEleve)).unwrap();
    dispatch(getEleves());
  }

  function nouveau(value) {
    if (value) {
      setNouveauxEleves([]);
    }
    setModalNouveauActive(value);
  }

  async function validerNouveau() {
    const nouveauxEnErreur =
      nouveauxEleves.filter((e) => !e.login || e.login === "" || !e.nom || e.nom === "" || !e.prenom || e.prenom === "" || !e.mdp || e.mdp === "").length > 0;
    if (nouveauxEnErreur) {
      return;
    }
    await dispatch(addEleves(nouveauxEleves)).unwrap();
    dispatch(getEleves());
    setModalNouveauActive(false);
  }

  function coller(e) {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const lines = paste.split("\r\n");
    const nouveauxEleves = lines
      .filter((line) => line && line !== "")
      .map((line, i) => {
        const parts = line.split("\t");
        const nom = cleanNom(parts[0]);
        const prenom = cleanNom(parts[1]);
        return { id: i, nom: nom, prenom: prenom, mdp: generatePass(), login: generateLogin(nom, prenom) };
      });
    gestionLoginEnDouble(nouveauxEleves);
    setNouveauxEleves(nouveauxEleves);
  }

  function gestionLoginEnDouble(nouveauxEleves) {
    const supLastNumber = (e) => (isNaN(e.charAt(e.length - 1)) ? e : e.substring(0, e.length - 1));
    const getLastNumber = (e) => (isNaN(e.charAt(e.length - 1)) ? 0 : parseInt(e.substring(e.length - 1, e.length)));
    const gestionLogin = (a, eleve, existant) => {
      var arrayElement = a.filter((e) => e.login === supLastNumber(eleve.login)).shift();
      var index = getLastNumber(eleve.login);
      if (existant) index++;
      if (!arrayElement) {
        arrayElement = { login: supLastNumber(eleve.login), eleves: [], index: index };
        a.push(arrayElement);
      }
      arrayElement.index = arrayElement.index < index ? index : arrayElement.index;
      arrayElement.eleves.push(eleve);
      return a;
    };
    const loginExistant = eleves.reduce((a, e) => gestionLogin(a, e, true), []);
    const nouveauAvecLoginExistant = nouveauxEleves
      .reduce((a, e) => gestionLogin(a, e, false), loginExistant)
      .filter((a) => a.eleves.filter((eleve) => nouveauxEleves.findIndex((n) => n.id === eleve.id) > -1).length > 0)
      .filter((a) => a.eleves.length > 1);
    nouveauAvecLoginExistant.forEach((a) =>
      a.eleves
        .filter((eleve) => nouveauxEleves.findIndex((n) => n.id === eleve.id) > -1)
        .forEach((eleve, i) => {
          eleve.login = a.index > 0 ? supLastNumber(eleve.login) + a.index : supLastNumber(eleve.login);
          a.index++;
        })
    );
  }

  function exportNouveauxCsv() {
    const content = nouveauxEleves.map((e) => [e.nom, e.prenom, e.login, e.mdp].join(";")).join("\n");
    const csvContent = `data:text/csv;charset=utf-8,nom;prenom;login;mdp\n${content}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  function changeNouveauNom(eleve, nom) {
    setNouveauxEleves(
      nouveauxEleves.map((e) => {
        return e.id === eleve.id ? { id: eleve.id, nom: nom, prenom: eleve.prenom, mdp: eleve.mdp, login: eleve.login } : e;
      })
    );
  }

  function changeNouveauPrenom(eleve, prenom) {
    setNouveauxEleves(
      nouveauxEleves.map((e) => {
        return e.id === eleve.id ? { id: eleve.id, nom: eleve.nom, prenom: prenom, mdp: eleve.mdp, login: eleve.login } : e;
      })
    );
  }

  function changeNouveauLogin(eleve, login) {
    const nouveaux = nouveauxEleves.map((e) => {
      return e.id === eleve.id ? { id: eleve.id, nom: eleve.nom, prenom: eleve.prenom, mdp: eleve.mdp, login: login } : e;
    });
    gestionLoginEnDouble(nouveaux);
    setNouveauxEleves(nouveaux);
  }

  function changeNouveauMdp(eleve, mdp) {
    setNouveauxEleves(
      nouveauxEleves.map((e) => {
        return e.id === eleve.id ? { id: eleve.id, nom: eleve.nom, prenom: eleve.prenom, mdp: mdp, login: eleve.login } : e;
      })
    );
  }

  return (
    <AdminTemplate>
      <div className="columns">
        <div className="column col-5 col-md-12">
          <ListSelect
            maxHeight="calc(100vh - 180px)"
            elements={eleves}
            addElement={() => nouveau(true)}
            selectedElement={selectedEleve}
            selectedElementChange={(eleve) => changeSelectedEleve(eleve)}
            filterDeactivated={(eleve) => !eleve.archive}
            filterElement={(eleve, filter) =>
              eleve.nom.toUpperCase().includes(filter.toUpperCase()) ||
              eleve.prenom.toUpperCase().includes(filter.toUpperCase()) ||
              eleve.login.toUpperCase().includes(filter.toUpperCase())
            }
            sortElement={(eleve1, eleve2) => (eleve1.nom + eleve1.prenom).localeCompare(eleve2.nom + eleve2.prenom)}
          >
            {(eleve) => eleve ? (
              <div>
                <span>{eleve?.nom} {eleve?.prenom}</span>
              </div>
            )  : (
              <span>Choisir un élève</span>
            )}
          </ListSelect>
        </div>
        {selectedEleve && (
          <>
            <div className="column col-12 mt-2 show-md">
              <div className="divider show-md"></div>
            </div>
            <div className="divider-vert hide-md"></div>
            <div className="column col-6 col-md-12">
              <div className="form-group">
                <label className="form-label" forhtml="input-nom">
                  Nom
                </label>
                <input className="form-input" type="text" id="input-nom" placeholder="nom" value={selectedEleve.nom} onChange={(e) => changeNom(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-prenom">
                  Prénom
                </label>
                <input className="form-input" type="text" id="input-prenom" placeholder="prénom" value={selectedEleve.prenom} onChange={(e) => changePrenom(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-login">
                  Login
                </label>
                <input className="form-input" type="text" id="input-login" placeholder="login" value={selectedEleve.login} onChange={(e) => changeLogin(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-password">
                  Mot de passe
                </label>
                <div className="input-group">
                  <input className="form-input" type="text" id="input-password" placeholder="password" value={selectedEleve.mdp} onChange={(e) => changePassword(e.target.value)} />
                  <button className="btn btn-secondary input-group-btn" onClick={() => randPassword()}>
                    <svg width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
                      <use xlinkHref="/iconSprites.svg#dice-fill" />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" forhtml="input-archive">
                  Groupes
                </label>
                <div className="input-group">
                  <div className="container">
                    <List elements={selectedEleve.groupes} keyPrefix={"groupe"}>
                      {(g) => (
                        <div className="columns">
                          <div className="column col">{g.nom}</div>
                          <div className="column col">{g.annee}</div>
                          <div className="column col-auto">
                            <button className="btn btn-sm btn-error input-group-btn s-circle" onClick={(e) => supprimeGroupe(g)}>
                              <i className="icon icon-cross"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </List>
                    <div className="columns m-2">
                      <Select
                        elements={groupesPool}
                        select={selectedGroupe}
                        emptyElement={true}
                        emptyElementLabel={"Choisir un groupe"}
                        keySelector={(c) => `${c.nom} ${c.annee}`}
                        valueSelector={(c) => `${c.nom} ${c.annee}`}
                        labelSelector={(c) => `${c.nom} ${c.annee}`}
                        onSelectChange={(c) => setSelectedGroupe(c)}
                      />
                      <button className="btn btn-success input-group-btn" disabled={selectedGroupe === ""} onClick={(e) => ajouteGroupe()}>
                        <i className="icon icon-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" forhtml="input-archive">
                  Archiver
                </label>
                <label className="form-switch is-error">
                  <input type="checkbox" id="input-archive" checked={selectedEleve.archive} onChange={() => changeArchive()} />
                  <i className="form-icon"></i>
                </label>
              </div>

              <div>
                <button className="btn btn-primary longButton mt-2" onClick={() => sauvegarder()}>
                  Sauvegarder
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal title="Nouveaux élèves" keySuffix="copieModal" active={modalNouveauActive} onCancel={() => nouveau(false)} onValidate={() => validerNouveau()}>
        <div contentEditable="true" onPaste={(e) => coller(e)}>
          Coller ici
        </div>
        <ScrollView height="300px" maxHeight="300px" margin="0 0 0 0">
          {nouveauxEleves && nouveauxEleves.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="bg-secondary">nom</th>
                  <th className="bg-secondary">prénom</th>
                  <th className="bg-secondary">login</th>
                  <th className="bg-secondary">mdp</th>
                </tr>
              </thead>
              <tbody>
                {nouveauxEleves.map((eleve) => (
                  <tr>
                    <td>
                      <input
                        className="form-input"
                        type="text"
                        id="input-nouveaunom"
                        placeholder="nom"
                        value={eleve.nom}
                        onChange={(e) => changeNouveauNom(eleve, e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        className="form-input"
                        type="text"
                        id="input-nouveauprenom"
                        placeholder="prénom"
                        value={eleve.prenom}
                        onChange={(e) => changeNouveauPrenom(eleve, e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        className="form-input"
                        type="text"
                        id="input-nouveaulogin"
                        placeholder="login"
                        value={eleve.login}
                        onChange={(e) => changeNouveauLogin(eleve, e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        className="form-input"
                        type="text"
                        id="input-nouveaumdp"
                        placeholder="mdp"
                        value={eleve.mdp}
                        onChange={(e) => changeNouveauMdp(eleve, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ScrollView>
        <div>
          <button className="btn btn-secondary longButton mt-2" onClick={() => exportNouveauxCsv()}>
            Exporter
          </button>
        </div>
      </Modal>
    </AdminTemplate>
  );
}
