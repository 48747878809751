import "../styles/Menu.css";

import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { disconnect, checkStoredUser } from "../store/user";
import { resetDevoir } from "../store/devoirs";
import { resetDico } from "../store/dicoSiiNsi";
import { resetEleves } from "../store/eleves";
import { useEffect, useState } from "react";

const menuItems = [
  {
    to: "/",
    name: "Accueil",
    tooltip: "Home",
    tooltipDirection: "right",
    icon: "home-fill",
    connected: false,
    admin: false,
  },
  // {
  //   to: "/exercices",
  //   name: "exercices",
  //   tooltip: "Exercices",
  //   tooltipDirection: "bottom",
  //   icon: "file-earmark-plus-fill",
  //   connected: false,
  //   admin: false,
  // },
  {
    to: "/simulation",
    name: "Simulation",
    tooltip: "Simulations",
    tooltipDirection: "bottom",
    icon: "award-fill",
    connected: false,
    admin: false,
  },
  {
    to: "/python",
    name: "Python",
    tooltip: "Python",
    tooltipDirection: "bottom",
    icon: "python",
    connected: false,
    admin: false,
  },
  {
    to: "/documents",
    name: "Documents",
    tooltip: "Documents",
    tooltipDirection: "bottom",
    icon: "file-earmark-arrow-down-fill",
    connected: true,
    admin: true,
  },
  {
    to: "/administration",
    name: "Administration",
    tooltip: "Administration",
    tooltipDirection: "bottom",
    icon: "gear-fill",
    connected: false,
    admin: true,
  },
  {
    to: "/utilisateur",
    name: "Utilisateur",
    tooltip: "Utilisateur",
    tooltipDirection: "bottom",
    icon: "book-fill",
    connected: true,
    admin: false,
  },
];

export default function Menu() {
  const isConnected = useSelector((state) => state.user.isConnected);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkStoredUser());
  }, [dispatch, isAdmin]);

  function menuItem(param, displayName = false) {
    const display = (!param.connected && !param.admin) || (param.connected && isConnected && !isAdmin) || (param.admin && isAdmin);

    return !display ? null : (
      <>
        <NavLink
          key={param.name}
          to={param.to}
          className={({ isActive, isPending }) => "d-block tooltip tooltip-" + param.tooltipDirection + (isActive ? " text-primary" : " text-dark") + (open ? "" : " mr-2")}
          data-tooltip={param.tooltip}
          onClick={(e) => setOpen(false)}
        >
          <div className="d-flex">
            <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
              <use xlinkHref={"/iconSprites.svg#" + param.icon} />
            </svg>
            {displayName && <span className="ml-2">{param.name}</span>}
          </div>
        </NavLink>
      </>
    );
  }

  function disconnectUser(e) {
    resetDevoir();
    resetDico();
    resetEleves();
    dispatch(disconnect());
  }

  return (
    <>
      <div className="container bg-secondary text-dark show-sm">
        <div className="columns">
          <div className="column col-auto mt-2">
            <NavLink class={"d-block " + (open ? "text-primary active" : "text-dark")} onClick={(e) => setOpen(!open)}>
              <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                <use xlinkHref="/iconSprites.svg#list" />
              </svg>
            </NavLink>
          </div>
          <div className="column col">
            <h5 className="mt-2">SII - NSI</h5>
          </div>
          <div className="column col-auto mt-2">
            <span>{user.login}</span>
          </div>
          <div className="column col-auto mt-2">
            {!isConnected ? (
              <NavLink to="/login" className="text-dark tooltip tooltip-left" data-tooltip="Connexion">
                <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                  <use xlinkHref="/iconSprites.svg#person-fill" />
                </svg>
              </NavLink>
            ) : (
              <>
                <NavLink onClick={disconnectUser} to="/" className="text-dark tooltip tooltip-left" data-tooltip="Déconnexion">
                  <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                    <use xlinkHref="/iconSprites.svg#person-x-fill" />
                  </svg>
                </NavLink>
              </>
            )}
          </div>
        </div>
        {open &&        
         (<div className="pb-2">
            {menuItems.map((m) => (
              <div className="columns">
                <div className="column col-auto col-mx-auto mt-2">{menuItem(m, true)}</div>
              </div>
            ))}
        </div>)}
      </div>

      <header className="navbar bg-secondary text-dark hide-sm">
        <section className="navbar-section">{menuItems.map((m) => menuItem(m))}</section>
        <section className="navbar-center">
          <h5 className="mt-1">SII - NSI</h5>
        </section>
        <section className="navbar-section">
          {!isConnected ? (
            <NavLink to="/login" className="text-dark m-2 tooltip tooltip-left" data-tooltip="Connexion">
              <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                <use xlinkHref="/iconSprites.svg#person-fill" />
              </svg>
            </NavLink>
          ) : (
            <>
              <span>{user.login}</span>
              <NavLink onClick={disconnectUser} to="/" className="text-dark m-2 tooltip tooltip-left" data-tooltip="Déconnexion">
                <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
                  <use xlinkHref="/iconSprites.svg#person-x-fill" />
                </svg>
              </NavLink>
            </>
          )}
        </section>
      </header>
    </>
  );
}
